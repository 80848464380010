import cogoToast, { CToast, CTOptions } from 'cogo-toast';

const getOptions = (options?: CTOptions): CTOptions => {
    return {
        position: 'top-right',
        bar: {
            size: '14px',
        },
        hideAfter: 5,
        ...options,
    }
}

const success = (message: string, options?: CTOptions) =>
    cogoToast.success(message, {
        heading: options?.heading || 'Success',
        ...getOptions(options),
    });

const error = (message: string, options?: CTOptions) =>
    cogoToast.error(message, {
        heading: options?.heading || 'Error',
        ...getOptions(options),
    });

const info = (message: string, options?: CTOptions) =>
    cogoToast.info(message, {
        heading: options?.heading || 'Info',
        ...getOptions(options),
    });

const loading = (message: string, options?: CTOptions) =>
    cogoToast.loading(message, {
        heading: options?.heading || 'Loading',
        ...getOptions(options),
    });

const warn = (message: string, options?: CTOptions) =>
    cogoToast.warn(message, {
        heading: options?.heading || 'Warn',
        ...getOptions(options),
    });
    
const showToast = (type: keyof CToast, message: string, options?: CTOptions) => {
    switch (type) {
        case "error":
            error(message, options);
            break;
        case "info":
            info(message, options);
            break;
        case "loading":
            loading(message, options);
            break;
        case "success":
            success(message, options);
            break;
        case "warn":
            warn(message, options);
            break;
        default:
            success(message, options);
    }
}

export { success, error, info, loading, warn };
export default showToast;
