import ReactDOM from 'react-dom/client';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import { BrowserRouter } from 'react-router-dom';

import { MultipleAuthProviders } from 'helpers/providers/MultipleAuthProviders';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


root.render(
    <Provider store={store}>
        <BrowserRouter>
            <MultipleAuthProviders>
                <App/>
            </MultipleAuthProviders>
        </BrowserRouter>
    </Provider>
);

reportWebVitals();
