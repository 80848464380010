// import { useOktaAuth } from '@okta/okta-react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

const Root = () => {
    const auth = useAuth()

    return <Navigate to={`/${auth.isAuthenticated ? 'home' : 'account/login'}`} />
};

export default Root;
